import { HttpHeaders } from '@angular/common/http'

import { userDefaultSideNavSettings } from '@shared/constants'
import { MapApiEnum } from '@view/map/map-enums.view'
import { IMapUiControlSessionSettings } from '@view/pages/dashboard-page/dashboard-page.view'
import { MapConfigSteps } from '@view/pages/school-map-config-page/school-map-config.view'
import { WelcomePageFeature } from '@view/user/welcome-user.view'
import { EnvOptions } from './env-options'
import { FEATURE_FLAGS_CONFIG_DEFAULTS, IOwlFeatureFlags } from './feature-flags.model'
import { LogConfigKeyValue, OwlLoggingArea, OwlLogLevels } from '@service/logging/logging.model'

export interface IAppConfigService {
  config: IAppConfig
  useAuth: boolean
  init(): void
  getRequestOptions(): { headers: HttpHeaders }
  handleAppConfigFromServer(data: any): void
  useMockData(): boolean
  getConfig(): IAppConfig | undefined
  getFullPath(path: string, method: string | undefined | null): string
}

export interface B2cMsalConfig {
  CLIENT_ID: string
  TENANT_ID: string
  TENANT_URL: string
  TENANT_B2C_URL: string
  SCOPE: string

  SIGN_IN_POLICY: string
  SIGN_UP_POLICY: string

  SIGN_IN_POLICY_SMS_MFA: string
  SIGN_IN_POLICY_PHONE_MFA: string
  SIGN_IN_POLICY_EMAIL_MFA: string
  SIGN_IN_POLICY_AUTH_APP_MFA: string

  RESET_PWD_POLICY: string
  PROFILE_EDIT_POLICY: string
}
export interface IAppConfig {
  APP_INSIGHTS_CONNECTION_STRING: string
  API_URL: string
  WEB_SOCKET_SIGNALR_DIR: string
  FEATURE_FLAGS: IOwlFeatureFlags
  WEB_SOCKET_SIGNALR_LOCATIONS_DIR: string
  SIGNALR_RECONNECT_MAX_WAIT: number
  API_VERSION: string

  USE_AUTH: boolean
  USE_LOGGING: boolean
  LOGGING_FLAGS: LogConfigKeyValue

  USE_MOCK_API_DATA: boolean
  USE_MOCK_SIGNALR_DATA: boolean

  env: EnvOptions

  OWL_B2C_MSAL_CONFIG: B2cMsalConfig

  ARC_GIS_API_KEY: string
  MAP_BOX_API_KEY: string
  MAP_CONFIG_SAVE_DEBOUNCE_TIME: number
  SCHOOL_AREA_NAME_MAX_CHAR: number
  MAP_API: MapApiEnum
  SHOW_MAP_MANUALLY_TIMEOUT_MS: number
  HISTORIC_CONTENT_WINDOW_MINUTES: number
  HISTORIC_DATA_LATENCY_BUFFER_SECONDS: number
  CLEAR_STALE_DASH_DATA_INTERVAL_SECONDS: number
  ZOOM_LEVEL_VISIBILITY: number
  HIDDEN_CONTENT_MESSAGE: string
  PROXIMITY_DISTANCE_IN_FEET: number
  BOUNDARY_PERIMETER_DEFAULT_PROXIMITY_IN_FEET: number
  BOUNDARY_PERIMETER_MAX_PROXIMITY_IN_FEET: number
  THREAT_MODEL_CONFIG: ThreatModelConfig
  INSTRUCTION_MAX_NEW_LINE_RETURN: number
  INSTRUCTION_MAX_CHARACTERS_PER_MESSAGE: number
  UPDATES_MAX_CHARACTERS_PER_MESSAGE: number
  CONCLUDE_EVENT_MODAL_CONTENT: ConcludeEventModalContent
  SIDE_NAV_DEFAULTS: IMapUiControlSessionSettings
  MAP_CONFIG_INSTRUCTIONS: Record<MapConfigSteps, MapConfigInstructionsText>
  APP_TIMEOUT_MINUTES: number
  APP_TIMEOUT_WARNING_SECONDS: number
  APP_TIMEOUT_EVENT_THROTTLE_MS: number
  RECAPTCHA_SITE_KEY: string
  TERMS_AND_CONDITIONS_LINK: string
  END_USER_LICENSE_AGREEMENT_LINK: string
  PRIVATE_POLICY_LINK: string
}

export interface MapConfigSubSection {
  SUB_TITLE: string
  SUB_TEXT: string
}

export interface MapConfigInstructionsText {
  TITLE: string
  TITLE_SUBTEXT: string[]
  OPTIONAL_SUBSECTION?: MapConfigSubSection
}
export interface ConcludeEventModalContent {
  TITLE: string
  TITLE_SUBTEXT: string
  CONFIRM_BUTTON_TEXT: string
  CANCEL_BUTTON_TEXT: string
}
export class DefaultConcludeEventModalContent implements ConcludeEventModalContent {
  TITLE = 'End Emergency'
  TITLE_SUBTEXT =
    'By clicking “Continue”, the entire school will be notified that the emergency has ended. Are you sure you want to continue?'
  CONFIRM_BUTTON_TEXT = 'Continue'
  CANCEL_BUTTON_TEXT = 'Cancel'
}
export class ThreatModelConfig {
  THREAT_POINT_BUFFER = 30
  MAX_AGE_THREAT_INDICATOR_THRESHOLD_SECONDS = 300
  THREAT_AREA_UPDATE_INTERVAL_SECONDS = 30
}

export class DefaultMapConfigInstructionsText implements MapConfigInstructionsText {
  TITLE = 'Title'
  TITLE_SUBTEXT = ['Subtext']
}

export class DefaultB2cMsalConfig implements B2cMsalConfig {
  CLIENT_ID = ''
  TENANT_ID = ''

  TENANT_URL = ''
  TENANT_B2C_URL = ''
  SCOPE = ''

  SIGN_IN_POLICY = ''
  SIGN_UP_POLICY = ''

  SIGN_IN_POLICY_SMS_MFA = ''
  SIGN_IN_POLICY_PHONE_MFA = ''
  SIGN_IN_POLICY_EMAIL_MFA = ''
  SIGN_IN_POLICY_AUTH_APP_MFA = ''

  RESET_PWD_POLICY = ''
  PROFILE_EDIT_POLICY = ''
}

export const defaultB2cConfig = new DefaultB2cMsalConfig()

export class DefaultAppConfig implements IAppConfig {
  APP_INSIGHTS_CONNECTION_STRING = ''
  WELCOME_PAGE_FEATURE = WelcomePageFeature.signUp
  WEB_SOCKET_SIGNALR_DIR = ''
  FEATURE_FLAGS = FEATURE_FLAGS_CONFIG_DEFAULTS
  WEB_SOCKET_SIGNALR_LOCATIONS_DIR = ''
  SIGNALR_RECONNECT_MAX_WAIT = 300000
  API_URL = ''
  API_VERSION = ''

  USE_AUTH = true
  USE_MOCK_API_DATA = false
  USE_MOCK_SIGNALR_DATA = false
  USE_LOGGING = false

  LOGGING_FLAGS = {
    [OwlLoggingArea.AUTH]: OwlLogLevels.NONE,
    [OwlLoggingArea.MAP]: OwlLogLevels.NONE,
    [OwlLoggingArea.ROUTING]: OwlLogLevels.NONE,
    [OwlLoggingArea.EFFECTS]: OwlLogLevels.NONE
  }

  env = EnvOptions.LOCAL
  OWL_B2C_MSAL_CONFIG = defaultB2cConfig

  ARC_GIS_API_KEY = ''
  MAP_BOX_API_KEY = ''
  MAP_CONFIG_SAVE_DEBOUNCE_TIME = 2000
  SCHOOL_AREA_NAME_MAX_CHAR = 12
  MAP_API = MapApiEnum.arcGis
  SHOW_MAP_MANUALLY_TIMEOUT_MS = 30000
  HISTORIC_CONTENT_WINDOW_MINUTES = 480
  HISTORIC_DATA_LATENCY_BUFFER_SECONDS: number = 1
  CLEAR_STALE_DASH_DATA_INTERVAL_SECONDS: number = 60
  ZOOM_LEVEL_VISIBILITY = 17
  HIDDEN_CONTENT_MESSAGE = ''
  PROXIMITY_DISTANCE_IN_FEET = 1
  BOUNDARY_PERIMETER_MAX_PROXIMITY_IN_FEET = 500
  BOUNDARY_PERIMETER_DEFAULT_PROXIMITY_IN_FEET = 5
  THREAT_MODEL_CONFIG: ThreatModelConfig = new ThreatModelConfig()
  INSTRUCTION_MAX_NEW_LINE_RETURN = 6
  INSTRUCTION_MAX_CHARACTERS_PER_MESSAGE = 120
  UPDATES_MAX_CHARACTERS_PER_MESSAGE = 150
  CONCLUDE_EVENT_MODAL_CONTENT = new DefaultConcludeEventModalContent()
  SIDE_NAV_DEFAULTS = userDefaultSideNavSettings
  MAP_CONFIG_INSTRUCTIONS = {
    [MapConfigSteps.addAreas]: new DefaultMapConfigInstructionsText(),
    [MapConfigSteps.mapView]: new DefaultMapConfigInstructionsText(),
    [MapConfigSteps.schoolPerimeter]: new DefaultMapConfigInstructionsText()
  }
  APP_TIMEOUT_MINUTES = 30
  APP_TIMEOUT_WARNING_SECONDS = 10
  APP_TIMEOUT_EVENT_THROTTLE_MS = 1000
  RECAPTCHA_SITE_KEY = ''
  TERMS_AND_CONDITIONS_LINK = ''
  END_USER_LICENSE_AGREEMENT_LINK = ''
  PRIVATE_POLICY_LINK = ''
}

export const defaultAppConfig = new DefaultAppConfig()
