import { getExtendedAction } from '@action/extended-ngrx-action'
import { savePrivacyAgreementAccepted } from '@action/school/school-validation-page.action'
import { Component, Inject } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { BtnColorVariants } from '@component/shared/button/button.component.view'
import { BaseModalComponent } from '@component/shared/modal/base-modal.component'
import {
  PrivacyAgreementDto,
  PrivacyAgreementFormModel,
  PrivacyAgreementFormProps
} from '@model/user/privacy-agreement.model'
import { Store } from '@ngrx/store'
import { AppConfigService } from '@service/app-config/app-config.service'
import { ModalViewModel } from '@view/matieral/modal.view'
import { PrivacyAgreementModalViewModel } from '@view/user/privacy-agreement.view'

@Component({
  selector: 'app-privacy-agreement-form',
  templateUrl: './privacy-agreement-form.component.html',
  styleUrls: ['./privacy-agreement-form.component.scss']
})
export class PrivacyAgreementFormComponent extends BaseModalComponent<PrivacyAgreementFormComponent> {
  cancelButtonType = BtnColorVariants.secondary
  allowedChars = /^[a-zA-Z .'-]$/

  userAgreementAccepted: boolean = false
  privacyPolicyAccepted: boolean = false
  name: String = ''
  date: Date = new Date()

  get icon(): string {
    return this.vm.iconName
  }
  get showIcon(): boolean {
    return !!this.vm.iconName
  }
  get titleSvgPath(): string {
    return `./assets/icons/${this.icon}.svg`
  }

  get nameFieldLabelText(): string {
    return PrivacyAgreementModalViewModel.nameFieldLabelText
  }

  get dateFieldLabelText(): string {
    return PrivacyAgreementModalViewModel.dateFieldLabelText
  }

  get readAndAcceptedStatement(): string {
    return PrivacyAgreementModalViewModel.readAndAcceptedStatement
  }

  get termsAndConditionsLinkText(): string {
    return PrivacyAgreementModalViewModel.termsAndConditionsLinkText
  }

  get licenceAgreementLinkText(): string {
    return PrivacyAgreementModalViewModel.licenceAgreementLinkText
  }

  get privatePolicyLinkText(): string {
    return PrivacyAgreementModalViewModel.privatePolicyLinkText
  }

  get termsAndConditionsLink(): string {
    return this.appConfig.config.TERMS_AND_CONDITIONS_LINK
  }

  get licenceAgreementLink(): string {
    return this.appConfig.config.END_USER_LICENSE_AGREEMENT_LINK
  }

  get privatePolicyLink(): string {
    return this.appConfig.config.PRIVATE_POLICY_LINK
  }

  get canContinue() {
    return this._form.isValid()
  }

  constructor(
    public dialogRef: MatDialogRef<PrivacyAgreementFormComponent>,
    @Inject(MAT_DIALOG_DATA) public vm: ModalViewModel,
    private appConfig: AppConfigService,
    private _form: PrivacyAgreementFormModel,
    private store: Store
  ) {
    super(dialogRef, vm)
  }

  onTermsAndConditionsLinkClick = (event: Event) => {
    this._form
      .getFormControlByKey(PrivacyAgreementFormProps.termsAndConditionsLinkClicked)
      .setValue(true)
  }

  onLicenceAgreementLinkClick = (event: Event) => {
    this._form
      .getFormControlByKey(PrivacyAgreementFormProps.licenceAgreementLinkClicked)
      .setValue(true)
  }

  onPrivatePolicyLinkClick = (event: Event) => {
    this._form
      .getFormControlByKey(PrivacyAgreementFormProps.privatePolicyLinkClicked)
      .setValue(true)
  }

  validateNameInput = (event: KeyboardEvent) => {
    if (!this.allowedChars.test(event.key)) {
      event.preventDefault()
    }
  }

  getFormControlValueByKey = (k: string): FormControl => {
    let key = PrivacyAgreementFormProps.fullName
    switch (k) {
      case 'userAgreementAccepted':
        key = PrivacyAgreementFormProps.userAgreementAccepted
        break
      case 'privacyPolicyAccepted':
        key = PrivacyAgreementFormProps.privacyPolicyAccepted
        break
      case 'fullName':
        key = PrivacyAgreementFormProps.fullName
        break
    }
    return this._form.getFormControlByKey(key)
  }

  confirm = () => {
    const dto = this._form.getFormValues() as PrivacyAgreementDto
    this.store.dispatch(savePrivacyAgreementAccepted(getExtendedAction(dto)))
    this.onConfirm()
  }
}
