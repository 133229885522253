import { Injectable } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { BaseFormModel } from '@model/base-form.model'
import { CustomFormValidators } from '@model/shared/custom-form-validators.model'

export enum PrivacyAgreementFormProps {
  fullName = 'fullName',
  userAgreementAccepted = 'userAgreementAccepted',
  privacyPolicyAccepted = 'privacyPolicyAccepted',
  termsAndConditionsLinkClicked = 'termsAndConditionsLinkClicked',
  licenceAgreementLinkClicked = 'licenceAgreementLinkClicked',
  privatePolicyLinkClicked = 'privatePolicyLinkClicked'
}

export interface PrivacyAgreementDto {
  [PrivacyAgreementFormProps.fullName]: number
  [PrivacyAgreementFormProps.userAgreementAccepted]: boolean
  [PrivacyAgreementFormProps.privacyPolicyAccepted]: boolean
  [PrivacyAgreementFormProps.termsAndConditionsLinkClicked]: boolean
  [PrivacyAgreementFormProps.licenceAgreementLinkClicked]: boolean
  [PrivacyAgreementFormProps.privatePolicyLinkClicked]: boolean
}

@Injectable()
export class PrivacyAgreementFormModel extends BaseFormModel<PrivacyAgreementFormProps> {
  constructor(private fb: FormBuilder) {
    super()
  }

  private _createFormGroup = (): Record<PrivacyAgreementFormProps, FormControl> => {
    const minLength = 2
    const sharedValidators = CustomFormValidators.getFullNameValidators(this.fb, minLength)
    return {
      [PrivacyAgreementFormProps.fullName]: this.fb.control('', sharedValidators),
      [PrivacyAgreementFormProps.userAgreementAccepted]: this.fb.control(
        false,
        Validators.requiredTrue
      ),
      [PrivacyAgreementFormProps.privacyPolicyAccepted]: this.fb.control(
        false,
        Validators.requiredTrue
      ),
      [PrivacyAgreementFormProps.termsAndConditionsLinkClicked]: this.fb.control(
        false,
        Validators.requiredTrue
      ),
      [PrivacyAgreementFormProps.licenceAgreementLinkClicked]: this.fb.control(
        false,
        Validators.requiredTrue
      ),
      [PrivacyAgreementFormProps.privatePolicyLinkClicked]: this.fb.control(
        false,
        Validators.requiredTrue
      )
    }
  }

  private _createForm = () => {
    return this.fb.group(this._createFormGroup())
  }
  _form: FormGroup = this._createForm()
}
