import { Injectable } from '@angular/core'
import { GetUserDto, PostUserDto, UserDtoProps, UserDtoStatus } from '@model/user/user.model'
import { ApiService } from './api.service'
import { IUserApiService } from './user.service.model'
import { Observable } from 'rxjs'
import { PrivacyAgreementDto } from '@model/user/privacy-agreement.model'

@Injectable()
export class UserApiService implements IUserApiService {
  public readonly serviceBaseDir = 'User'
  public readonly onboardPath = 'onboard'
  constructor(private _apiService: ApiService) {}
  pingServer() {
    return this._apiService.get(`/service/ping`)
  }
  getUserByToken() {
    return this._apiService.get<GetUserDto>(`/${this.serviceBaseDir}/authenticated`)
  }
  getUsersForSchool(id: number) {
    return this._apiService.get<GetUserDto[]>(`/${this.serviceBaseDir}/all/${id}`)
  }
  inviteNotRegistered(schoolId: number, userType: string) {
    return this._apiService.post(
      `/${this.serviceBaseDir}/invitenotregistered?schoolId=${schoolId}&userType=${userType}`,
      null
    )
  }
  sendInvite(schoolId: number, userIds: number[]) {
    return this._apiService.post(`/${this.serviceBaseDir}/invite?schoolId=${schoolId}`, userIds)
  }
  deactivateUser(id: number) {
    return this._apiService.patch(`/${this.serviceBaseDir}/${id}`, {
      status: UserDtoStatus.deactivated
    })
  }
  //Create Update User
  createUser = (user: PostUserDto, schoolId: number) =>
    this._apiService.post(`/${this.serviceBaseDir}/${this.onboardPath}`, { ...user, schoolId })
  updateUser = (user: Partial<GetUserDto>): Observable<GetUserDto> =>
    this._apiService.patch(`/${this.serviceBaseDir}/${user.id ?? 0}`, user)
  savePrivacyAgreement = (dto: PrivacyAgreementDto) => {
    return this._apiService.post(`/${this.serviceBaseDir}/privacyAgreement`, dto)
  }
}
