<div class="modal-container" *ngIf="vm.readyToShow" [ngClass]="{ '--hide-icon': !showIcon }">
  <div class="_modal-row _icon-section" *ngIf="showIcon">
    <img src="{{ titleSvgPath }}" />
  </div>
  <div class="_modal-row _heading">
    {{ vm.titleText }}
  </div>
  <div class="_modal-row _content">
    {{ vm.promptText }}
  </div>
  <div class="_content">
    <div class="_modal-row _content-row">
      <mat-checkbox
        color="primary"
        [formControl]="getFormControlValueByKey('userAgreementAccepted')"
      ></mat-checkbox>
      <div class="_content-checkbox-label">
        {{ readAndAcceptedStatement }}
        <a
          href="{{ termsAndConditionsLink }}"
          target="_blank"
          rel="noopener noreferrer"
          (click)="onTermsAndConditionsLinkClick($event)"
          >{{ termsAndConditionsLinkText }}</a
        >
        and
        <a
          href="{{ licenceAgreementLink }}"
          target="_blank"
          rel="noopener noreferrer"
          (click)="onLicenceAgreementLinkClick($event)"
          >{{ licenceAgreementLinkText }}</a
        >
      </div>
    </div>
    <div class="_modal-row _content-row">
      <mat-checkbox
        color="primary"
        [formControl]="getFormControlValueByKey('privacyPolicyAccepted')"
      ></mat-checkbox>
      <div class="_content-checkbox-label">
        {{ readAndAcceptedStatement }}
        <a
          href="{{ privatePolicyLink }}"
          target="_blank"
          rel="noopener noreferrer"
          (click)="onPrivatePolicyLinkClick($event)"
          >{{ privatePolicyLinkText }}</a
        >
      </div>
    </div>
    <div class="_modal-row _content-row">
      <div class="_content-column">
        <input
          matInput
          [formControl]="getFormControlValueByKey('fullName')"
          (keypress)="validateNameInput($event)"
          required
        />
        <label>{{ nameFieldLabelText }}</label>
      </div>
      <div class="_content-column">
        <input class="_content-date" value="{{ date | date : 'dd-MMM-yyyy' }}" readonly />
        <label>{{ dateFieldLabelText }}</label>
      </div>
    </div>
  </div>
  <div class="_modal-row _action-buttons">
    <app-button
      *ngIf="!!vm.confirmButtonText"
      [mat-dialog-close]="true"
      (click)="confirm()"
      [buttonText]="vm.confirmButtonText"
      [buttonIcon]="vm.confirmButtonIcon"
      [disabled]="!canContinue"
    ></app-button>
    <app-button
      *ngIf="!!vm.cancelButtonText"
      [mat-dialog-close]="true"
      (click)="onCancel()"
      [buttonText]="vm.cancelButtonText"
      [buttonType]="cancelButtonType"
    ></app-button>
  </div>
</div>
