export class PrivacyAgreementModalViewModel {
  static readonly title = 'Welcome to OWL for Schools!'
  static readonly subtext = `Before you proceed, please read and agree to our policies:`
  static confirmBtnText = 'Continie'
  static cancelBtnText = 'Cancel'
  static nameFieldLabelText = 'Type Your Name'
  static dateFieldLabelText = `Today's Date`
  static readAndAcceptedStatement = 'I have read and agree to the OWL for Schools'
  static termsAndConditionsLinkText = 'Subscription Terms and Conditions'
  static licenceAgreementLinkText = 'End User License Agreement'
  static privatePolicyLinkText = 'Privacy Policy'
}
