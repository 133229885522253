import { ExtendedAction } from '@action/extended-ngrx-action'
import { SchoolValidationSearchDto } from '@model/school-validation.model'
import { PrivacyAgreementDto } from '@model/user/privacy-agreement.model'
import { createAction, props } from '@ngrx/store'
import { SchoolValidationViewModel } from '@view/school/school-validation.view'

export enum SchoolPageAction {
  SearchForSchool = '[SchoolPageAction] Search For School',
  UpdateFormValues = '[SchoolPageAction] Update Form Values',

  ShowModalForSchoolForUserConfirmation = '[SchoolPageAction] Show Modal for School for User Confirmation',
  SaveSchoolForUser = '[SchoolPageAction] Save School for User',

  SavePrivacyAgreementAccepted = '[SchoolPageAction] Save Privacy Agreement Accepted'
}

export const searchForSchoolLookupResult = createAction(
  SchoolPageAction.SearchForSchool,
  props<ExtendedAction<string>>()
)
export const updateSchoolValidationFormValues = createAction(
  SchoolPageAction.UpdateFormValues,
  props<ExtendedAction<SchoolValidationViewModel>>()
)
export const saveSchoolForUser = createAction(
  SchoolPageAction.SaveSchoolForUser,
  props<ExtendedAction<SchoolValidationSearchDto>>()
)
export const showModalConfirmSchoolValidation = createAction(
  SchoolPageAction.ShowModalForSchoolForUserConfirmation,
  props<ExtendedAction<SchoolValidationSearchDto>>()
)
export const savePrivacyAgreementAccepted = createAction(
  SchoolPageAction.SavePrivacyAgreementAccepted,
  props<ExtendedAction<PrivacyAgreementDto>>()
)
