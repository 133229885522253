import { HttpHeaders } from '@angular/common/http'
import { mockedAnonFn } from '@mock/typscript'
import { userDefaultSideNavSettings } from '@shared/constants'
import { MapApiEnum } from '@view/map/map-enums.view'
import { MapConfigSteps } from '@view/pages/school-map-config-page/school-map-config.view'
import {
  DefaultB2cMsalConfig,
  DefaultConcludeEventModalContent,
  DefaultMapConfigInstructionsText,
  IAppConfig,
  IAppConfigService,
  ThreatModelConfig
} from './app-config.model'
import { EnvOptions } from './env-options'
import { FEATURE_FLAGS_CONFIG_DEFAULTS } from './feature-flags.model'
import { OwlLoggingArea, OwlLogLevels } from '@service/logging/logging.model'

export const mockApiDomainCom = `api.domain.com`
export const mockPortalDomainCom = 'mock.domain.com'
export const mockAdminDomainCom = 'mock.admin.domain.com'
export const mockappInsightConnectionString = 'mockappInsightConnectionString'

export const mockAppConfig: IAppConfig = {
  API_URL: mockApiDomainCom,
  WEB_SOCKET_SIGNALR_DIR: 'mock/version',
  FEATURE_FLAGS: FEATURE_FLAGS_CONFIG_DEFAULTS,
  WEB_SOCKET_SIGNALR_LOCATIONS_DIR: 'mock/version',
  SIGNALR_RECONNECT_MAX_WAIT: 300000,
  API_VERSION: 'mock/version',
  APP_INSIGHTS_CONNECTION_STRING: mockappInsightConnectionString,
  OWL_B2C_MSAL_CONFIG: new DefaultB2cMsalConfig(),
  USE_AUTH: false,
  USE_MOCK_API_DATA: false,
  USE_MOCK_SIGNALR_DATA: false,
  USE_LOGGING: false,
  LOGGING_FLAGS: {
    [OwlLoggingArea.AUTH]: OwlLogLevels.NONE,
    [OwlLoggingArea.EFFECTS]: OwlLogLevels.NONE,
    [OwlLoggingArea.MAP]: OwlLogLevels.NONE,
    [OwlLoggingArea.ROUTING]: OwlLogLevels.NONE
  },
  env: EnvOptions.LOCAL_TEST,
  ARC_GIS_API_KEY: 'mockArcGisApiKey',
  MAP_CONFIG_SAVE_DEBOUNCE_TIME: 2000,
  SCHOOL_AREA_NAME_MAX_CHAR: 12,
  MAP_API: MapApiEnum.arcGis,
  MAP_BOX_API_KEY: 'mockMapBoxApiKey',
  SHOW_MAP_MANUALLY_TIMEOUT_MS: 30000,
  ZOOM_LEVEL_VISIBILITY: 17,
  PROXIMITY_DISTANCE_IN_FEET: 1,
  BOUNDARY_PERIMETER_DEFAULT_PROXIMITY_IN_FEET: 5,
  BOUNDARY_PERIMETER_MAX_PROXIMITY_IN_FEET: 500,
  THREAT_MODEL_CONFIG: new ThreatModelConfig(),
  INSTRUCTION_MAX_NEW_LINE_RETURN: 6,
  INSTRUCTION_MAX_CHARACTERS_PER_MESSAGE: 120,
  UPDATES_MAX_CHARACTERS_PER_MESSAGE: 150,
  HISTORIC_CONTENT_WINDOW_MINUTES: 480,
  HISTORIC_DATA_LATENCY_BUFFER_SECONDS: 2,
  CLEAR_STALE_DASH_DATA_INTERVAL_SECONDS: 60,
  CONCLUDE_EVENT_MODAL_CONTENT: new DefaultConcludeEventModalContent(),
  SIDE_NAV_DEFAULTS: userDefaultSideNavSettings,
  MAP_CONFIG_INSTRUCTIONS: {
    [MapConfigSteps.addAreas]: new DefaultMapConfigInstructionsText(),
    [MapConfigSteps.mapView]: new DefaultMapConfigInstructionsText(),
    [MapConfigSteps.schoolPerimeter]: new DefaultMapConfigInstructionsText()
  },
  HIDDEN_CONTENT_MESSAGE: 'All users are hidden on the map, no content to highlight',
  APP_TIMEOUT_MINUTES: 30,
  APP_TIMEOUT_WARNING_SECONDS: 10,
  APP_TIMEOUT_EVENT_THROTTLE_MS: 1000,
  RECAPTCHA_SITE_KEY: 'mockRecaptchaSiteKey',
  TERMS_AND_CONDITIONS_LINK: '',
  END_USER_LICENSE_AGREEMENT_LINK: '',
  PRIVATE_POLICY_LINK: ''
}
export class MockAppConfigService implements IAppConfigService {
  constructor() {}
  setDomainMapKey(domainMapKey: string): void {}
  getBrokerPromoLinkDomain(): string {
    return ''
  }
  useAuth: boolean = false
  getRequestOptions(): { headers: HttpHeaders } {
    return { headers: new HttpHeaders() }
  }
  handleAppConfigFromServer(data: any): void {
    return
  }
  useMockData(): boolean {
    return false
  }
  getFullPath(path: string, method: string | null | undefined): string {
    return ''
  }

  init = mockedAnonFn
  getConfig = (): IAppConfig | undefined => mockAppConfig
  config: IAppConfig = mockAppConfig
}

export const defaultMockAppConfigService = new MockAppConfigService()
